try{
  var t = localStorage
} catch(error) {
  alert('Can not start without access to local storage and cookies')
}
window.$ = window.jQuery = require('jquery')
var baseURL = localStorage.getItem('baseURL') || '/'

window.userRequest = $.ajax(baseURL + 'portal/User', {
    xhrFields: { withCredentials: true },
    headers: { 'X-Requested-With': 'DocAnalytics' }
  }).then(function(user) {
  if (user.ssoRedirect)
    location.replace(user.ssoRedirect)
  return user
})

window._ = require('lodash')
require('./tools/polyfill')
require('tslib')
require('velocity-animate')
try {
	window.noty = require('noty')
} catch (error) {
	console.error(error)
}
require('style-loader!css-loader!noty/lib/noty.css')
require('selectivity/apis/jquery')
require('selectivity/dropdown')
require('selectivity/event-listener')
require('selectivity/inputs/email')
require('selectivity/inputs/multiple')
require('selectivity/inputs/single')
require('selectivity/locale')
require('selectivity/plugins/ajax')
require('selectivity/plugins/async')
require('selectivity/plugins/jquery/ajax')
require('selectivity/plugins/jquery/traditional')
require('selectivity/plugins/keyboard')
require('selectivity/plugins/options-validator')
require('selectivity/plugins/submenu')
require('selectivity/selectivity')
require('selectivity/templates')
require('style-loader!css-loader!selectivity/styles/selectivity-jquery.css')
