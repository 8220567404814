if (!window.NATIVE_JS) {
	// we want to retain the original indexOf, as it is faster
	var originalIndexOf = [].indexOf
	require('core-js')

	Object.defineProperty(Array.prototype, 'indexOf', {
		value: originalIndexOf,
		enumerable: false,
		writable: true,
	})
	// polyfill for fixing bind to retain prototype chain of target function
	var funcPrototype = Object.getPrototypeOf(function() {})
	var originalBind = funcPrototype.bind
	funcPrototype.bind = function() {
		var bound = originalBind.apply(this, arguments)
		if (bound.__proto__ != this.__proto__) {
			bound.__proto__ = this.__proto__
		}
		return bound
	}
	if (!Object.setPrototypeOf) {
		// a little better polyfill for setPrototypeOf, that will preserver
		// getters/setters across copies
		Object.setPrototypeOf = function(target, proto) {
			var keys = Object.getOwnPropertyNames(proto)
			for (var i = 0, l = keys.length; i < l; i++) {
				var key = keys[i]
				if (!target.hasOwnProperty(key)) {
					Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(proto, key))
				}
			}
			for (var key in proto) {
				// get from prototype
				if (!target.hasOwnProperty(key)) {
					try {
						target[key] = proto[key]
					} catch (error) {}
				}
			}
		}
	}

	if (!window.Promise) { // than native promises are now faster, so only use bluebird to patch
		var Promise = require('bluebird') 
		Object.defineProperty(window, 'Promise', {
			get: function() {
				return Promise
			},
			set: function() {
				// and don't let any third-party integrations overwrite this (like usersnap),
				// and use configurable so overwrite failures won't throw an error on stop the integration from loading
			},
		})
	}
	var testDiv = document.createElement('div')
	if (!testDiv.classList) {
		Object.defineProperty(HTMLElement.prototype, 'classList', {
			get: function() {
				var element = this
				return {
					add: function(className) {
						for (var i = 0; i < arguments.length; i++) $(element).addClass(arguments[i])
					},
					remove: function(className) {
						for (var i = 0; i < arguments.length; i++) $(element).removeClass(arguments[i])
					},
					toggle: function(className, state) {
						$(element).toggleClass(className, state)
					},
				}
			},
		})
	}
	if (!testDiv.remove) {
		Object.defineProperty(HTMLElement.prototype, 'remove', {
			value: function() {
				this.parentNode.removeChild(this)
			},
		})
	}
	if (typeof window.Event != 'function') {
		window.Event = function(type) {
			var event = document.createEvent('Event')
			event.initEvent(type, true, true)
			return event
		}
	}
}
